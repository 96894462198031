import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="fixed bottom-0 left-0 z-20 w-full bg-[#030712] text-slate-300 md:hidden">
      <div className="container">
        <ul className="footerList flex w-full items-center justify-between text-[10px] font-medium capitalize md:hidden">
          <li className="flex flex-1 items-center justify-center text-center">
            <NavLink
              to="/"
              className="flex w-full items-center justify-center py-5 text-center leading-normal hover:border-b-2"
            >
              {t("header.home")}
            </NavLink>
          </li>
          <li className="flex flex-1 items-center justify-center text-center">
            <NavLink
              to="/about"
              className="flex w-full items-center justify-center py-5 text-center leading-normal hover:border-b-2"
            >
              {t("header.about me")}
            </NavLink>
          </li>
          <li className="flex flex-1 items-center justify-center text-center">
            <NavLink
              to="/skills"
              className="flex w-full items-center justify-center py-5 text-center leading-normal hover:border-b-2"
            >
              {t("header.skills")}
            </NavLink>
          </li>
          <li className="flex flex-1 items-center justify-center text-center">
            <NavLink
              to="/experience"
              className="flex w-full items-center justify-center py-5 text-center leading-normal hover:border-b-2"
            >
              {t("header.experience")}
            </NavLink>
          </li>
          <li className="flex flex-1 items-center justify-center text-center">
            <NavLink
              to="/work"
              className="flex w-full items-center justify-center py-5 text-center leading-normal hover:border-b-2"
            >
              {t("header.work")}
            </NavLink>
          </li>
          <li className="flex flex-1 items-center justify-center text-center">
            <NavLink
              to="/contact"
              className="flex w-full items-center justify-center py-5 text-center leading-normal hover:border-b-2"
            >
              {t("header.contact")}
            </NavLink>
          </li>
        </ul>
      </div>
    </footer>
  );
};

export default Footer;
