import React from "react";
import "./Loading.css";

const Loading = () => {
  return (
    <div className="fixed z-20 flex h-screen w-full items-center justify-center bg-[#030712]">
      <div className="loader">
        <div className="dot"></div>
        <div className="dot"></div>
        <div className="dot"></div>
      </div>
    </div>
  );
};

export default Loading;
