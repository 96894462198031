import React, { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import Loading from "../components/loading/Loading";

const Home = lazy(() => import("../components/home/Home"));
const About = lazy(() => import("../components/about/About"));
const Skills = lazy(() => import("../components/skills/Skills"));
const Experience = lazy(() => import("../components/experience/Experience"));
const Work = lazy(() => import("../components/work/Work"));
const Contact = lazy(() => import("../components/contact/Contact"));

const RouteController = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/skills" element={<Skills />} />
        <Route path="/experience" element={<Experience />} />
        <Route path="/work" element={<Work />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </Suspense>
  );
};

export default RouteController;
